import React from "react";

import { nanoid } from "nanoid";

import {
  Container,
  ImageContainer,
  OfferType,
  OfferTypeMobile,
  Image,
  CardInfo,
  CardInfoMobile,
  CardInfoDesktop,
  DescriptionContainer,
  BankName,
  CardDescription,
  BonusesContainer,
  Bonus,
  ConditionsContainer,
  Condition,
  ConditionTitle,
  ConditionValue,
  CardButton,
  AdvName,
} from "./styles";

const Card = ({ cardData }) => {
  const {
    isBest,
    offerType,
    image,
    bank,
    description,
    bonuses,
    conditions,
    link,
    advText,
  } = cardData;

  return (
    <Container isBestOffer={isBest}>
      <ImageContainer>
        <OfferType isBestOffer={isBest}>{offerType}</OfferType>
        <OfferTypeMobile isBestOffer={isBest}></OfferTypeMobile>
        <a href={link} rel="noreferrer" target="_blank">
          <Image src={`/images/${image}`} />
        </a>
        <CardInfoMobile>
          <BankName>{bank}</BankName>
          {description.map((item) => (
            <CardDescription key={nanoid()}>{item}</CardDescription>
          ))}
        </CardInfoMobile>
      </ImageContainer>
      <CardInfo>
        <AdvName>{advText}</AdvName>
        <DescriptionContainer>
          <CardInfoDesktop>
            <BankName>{bank}</BankName>
            {description.map((item) => (
              <CardDescription key={nanoid()}>{item}</CardDescription>
            ))}
          </CardInfoDesktop>
          <BonusesContainer>
            {bonuses.map((item) => (
              <Bonus key={nanoid()}>{item}</Bonus>
            ))}
          </BonusesContainer>
        </DescriptionContainer>
        <ConditionsContainer>
          {conditions.map((item) => (
            <Condition key={nanoid()}>
              <ConditionTitle>{item.name}</ConditionTitle>
              <ConditionValue>{item.value}</ConditionValue>
            </Condition>
          ))}
        </ConditionsContainer>
      </CardInfo>
      <CardButton href={link} target="_blank">
        Получить деньги
      </CardButton>
    </Container>
  );
};

export default React.memo(Card);
