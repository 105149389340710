import React, { useEffect, useState } from 'react';

import { nanoid } from 'nanoid';
import Slider from '@mui/material/Slider';

import { Container, Conditions, ConditionsText, ConditionsTitle, Cards, SliderWrap, SliderText, BestText } from './styles';
import Card from '../../components/card';

const ChooseCard = () => {
  const [cards, setCards] = useState([]);
  const [config, setConfig] = useState({
    limit: 0,
    period: 0,
    percent: 0,
  });
  const [filteredCards, setFilteredCards] = useState(cards);
  const [isDisplaysBest, setIsDisplaysBest] = useState(false);

  const filterCards = (cards, config) => {
    const filtered = cards.filter(item => (item.limit >= config.limit && item.period >= config.period && item.percent <= config.percent));

    if (filtered.length === 0) {
      const bestCard = cards.filter(item => item.isBest);

      setFilteredCards(bestCard);
      setIsDisplaysBest(true);

      return;
    }

    setFilteredCards(filtered);
  };

  const getCards = async () => {
    return fetch('/cards.json').then(data => data.json());
  };

  const getConfig = async () => {
    return fetch('/filter-config.json').then(data => data.json());
  };

  const setLimit = limit => {
    config.limit = limit;
  };

  const setPercent = percent => {
    config.percent = percent;
  };

  const setPeriod = period => {
    config.period = period;
  };

  useEffect(() => {
    Promise.all([getCards(), getConfig()]).then(responses => {
      setCards(responses[0]);
      setConfig(responses[1]);
      //filterCards(responses[0], responses[1]);
    }).catch(() => {
      alert('Что-то пошло не так! Перезагрузите страницу');
    });
  }, []);

  return(
    <Container>
      <Conditions>
        <ConditionsTitle>Сервис подбора микрозаймов</ConditionsTitle>
        {/*<SliderWrap>
          <SliderText>Кредитный лимит</SliderText>
          <Slider
            valueLabelDisplay="on"
            value={config.limit}
            min={0}
            max={1000000}
            step={10000}
            valueLabelFormat={value => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
            sx={{
              color: '#1847ED',
              '& .MuiSlider-thumb': {
                width: '16px',
                height: '16px',
                color: '#FFF',
              },
            }}
            onChange={(e, value) => {
              setLimit(value);
              filterCards(cards, config);
            }}
          />
        </SliderWrap>
        <SliderWrap>
          <SliderText>Льготный период</SliderText>
          <Slider
            valueLabelDisplay="on"
            value={config.period}
            min={0}
            max={120}
            valueLabelFormat={value => {
              const days = ['день', 'дня', 'дней'];
              const cases = [2, 0 , 1, 1, 1, 2];
              const text = days[value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 0]];
              
              return `${value} ${text}`;
            }}
            sx={{
              color: '#1847ED',
              '& .MuiSlider-thumb': {
                width: '16px',
                height: '16px',
                color: '#FFF',
              },
            }}
            onChange={(e, value) => {
              setPeriod(value);
              filterCards(cards, config);
            }}
          />
        </SliderWrap>
        <SliderWrap>
          <SliderText>Снятие наличных</SliderText>
          <Slider
            valueLabelDisplay="on"
            value={config.percent}
            min={0}
            max={10}
            step={0.1}
            valueLabelFormat={value => `${value}%`}
            sx={{
              color: '#1847ED',
              '& .MuiSlider-thumb': {
                width: '16px',
                height: '16px',
                color: '#FFF',
              },
            }}
            onChange={(e, value) => {
              setPercent(value);
              filterCards(cards, config);
            }}
          />
          </SliderWrap>*/}
      </Conditions>
      <Cards>
      {
        isDisplaysBest && (
          <>
            <BestText>По параметрам поиска подобрать карту не удалось</BestText>
            <BestText>Мы рекомендуем</BestText>
          </>
        )
      }
      {
        cards.map(item => (
          <Card
            key={nanoid()}
            cardData={item}
          />
        ))
      }
      </Cards>
    </Container>
  );
};

export default React.memo(ChooseCard);