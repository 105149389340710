import React, { useEffect, useState } from 'react';

import Agreement from './assets/agreement.pdf';
import Info from './assets/info.pdf';
import { CustomHeader, Title, Link, Burger, BurgerSpan, MobileMenu, MobileTitle, MobileLink, LinkContainer } from './styles';


const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [config, setConfig] = useState({});

  const openMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const getTextConfig = async () => {
    return fetch('/config.json').then(data => data.json());
  }

  useEffect(() => {
    getTextConfig().then(data => setConfig(data));
  }, []);

  return (
    <CustomHeader isOpen={isMobileMenuOpen}>
      <Title>{config.title}</Title>
      <LinkContainer>
        <Link target='_blank' href={Agreement} rel='noreferrer'>Политика в отношении обработки персональных данных</Link>
        <Link target='_blank' href={Info} rel='noreferrer'>Юридическая информация</Link>
      </LinkContainer>
      <Burger
        onClick={openMobileMenu}
      >
        <BurgerSpan isOpen={isMobileMenuOpen} isHidden={false}></BurgerSpan>
        <BurgerSpan isOpen={isMobileMenuOpen} isHidden={false}></BurgerSpan>
        <BurgerSpan isOpen={isMobileMenuOpen} isHidden={true}></BurgerSpan>
      </Burger>
      <MobileMenu isOpen={isMobileMenuOpen}>
        <MobileTitle>{config.title}</MobileTitle>
        <MobileLink target='_blank' href={Agreement} rel='noreferrer'>Политика в отношении обработки персональных данных</MobileLink>
        <MobileLink target='_blank' href={Info} rel='noreferrer'>Юридическая информация</MobileLink>
      </MobileMenu>
    </CustomHeader>
  );
};

export default React.memo(Header);