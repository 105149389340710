import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import { THEME } from '../../themes/main';

import Layout from '../layout';

const App = () => {
  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default React.memo(App);
