import { styled } from "@mui/system";
import { COLOR, FONT_FAMILY } from "../../themes/main";
import GoodOffer from "../../assets/good-offer.svg";
import BestOffer from "../../assets/best-offer.svg";
import { THEME } from "../../themes/main";

export const Container = styled(
  "div",
  {}
)(({ isBestOffer }) => ({
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  backgroundColor: isBestOffer ? COLOR.BEST_CARD : COLOR.LIGHT_GRAY,
  padding: "40px 20px 20px",
  borderRadius: "20px",
  marginBottom: "10px",

  "&:last-child": {
    marginBottom: "0",
  },

  [THEME.breakpoints.down("md")]: {
    padding: "30px 10px 20px",
    flexDirection: "column",
  },
}));

export const ImageContainer = styled(
  "div",
  {}
)(() => ({
  flex: "0 0 250px",
  marginRight: "50px",
  position: "relative",

  [THEME.breakpoints.down("xl")]: {
    marginRight: "15px",
  },

  [THEME.breakpoints.down("lg")]: {
    marginBottom: "30px",
    marginRight: 0,
  },

  [THEME.breakpoints.down("md")]: {
    flex: "auto",
    marginBottom: "15px",
    display: "flex",
  },
}));

export const OfferType = styled(
  "div",
  {}
)(({ isBestOffer }) => ({
  paddingLeft: "22px",
  position: "relative",
  fontFamily: FONT_FAMILY.HELVETICA,
  color: `rgba(${COLOR.TEXT_RGB}, .4)`,
  fontSize: "16px",
  lineHeight: "20px",
  marginBottom: "15px",

  [THEME.breakpoints.down("md")]: {
    display: "none",
  },

  "&::before": {
    content: '""',
    position: "absolute",
    display: "inline-block",
    width: "20px",
    height: "20px",
    left: "0",
    top: "0px",
    backgroundImage: `url(${isBestOffer ? BestOffer : GoodOffer})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
}));

export const OfferTypeMobile = styled(
  "div",
  {}
)(({ isBestOffer }) => ({
  position: "absolute",
  display: "none",
  width: "20px",
  height: "20px",
  left: "-10px",
  top: "-10px",
  borderRadius: "30px",
  border: `3px solid ${isBestOffer ? COLOR.BEST_CARD : COLOR.LIGHT_GRAY}`,
  backgroundColor: `${isBestOffer ? COLOR.BEST_CARD : COLOR.LIGHT_GRAY}`,
  backgroundImage: `url(${isBestOffer ? BestOffer : GoodOffer})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",

  [THEME.breakpoints.down("md")]: {
    display: "block",
  },
}));

export const Image = styled(
  "img",
  {}
)(() => ({
  width: "250px",
  height: "158px",

  [THEME.breakpoints.down("xs")]: {
    width: "126px",
    height: "80px",
  },
}));

export const CardInfo = styled(
  "div",
  {}
)(() => ({
  display: "flex",

  [THEME.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const AdvName = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  color: `rgba(${COLOR.TEXT_RGB}, .4)`,
  fontSize: "16px",
  position: "absolute",
  top: "5px",
  right: "20px",

  [THEME.breakpoints.down("xs")]: {
    fontSize: "10px",
  },
}));

export const CardInfoDesktop = styled(
  "div",
  {}
)(() => ({
  [THEME.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const CardInfoMobile = styled(
  "div",
  {}
)(() => ({
  display: "none",

  [THEME.breakpoints.down("md")]: {
    display: "block",
    marginLeft: "20px",
  },
}));

export const DescriptionContainer = styled("div", {})(() => ({}));

export const BankName = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "28px",
  color: COLOR.TEXT,
  lineHeight: 1,
  marginBottom: "5px",

  [THEME.breakpoints.down("xs")]: {
    fontSize: "16px",
  },
}));

export const CardDescription = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "20px",
  color: `rgba(${COLOR.TEXT_RGB}, .4)`,
  lineHeight: 1,

  [THEME.breakpoints.down("xs")]: {
    fontSize: "12px",
  },
}));

export const BonusesContainer = styled(
  "div",
  {}
)(() => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  maxWidth: "290px",

  [THEME.breakpoints.down("md")]: {
    maxWidth: "60%",
  },

  [THEME.breakpoints.down("sm")]: {
    maxWidth: "90%",
  },

  [THEME.breakpoints.down("xs")]: {
    maxWidth: "75%",
  },
}));

export const Bonus = styled(
  "div",
  {}
)(() => ({
  marginTop: "20px",
  padding: "7px 15px",
  backgroundColor: COLOR.WHITE,
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "14px",
  lineHeight: 1,
  color: `rgba(${COLOR.TEXT_RGB}, .5)`,
  borderRadius: "30px",
  textTransform: "uppercase",

  [THEME.breakpoints.down("md")]: {
    marginTop: 0,
    marginBottom: "10px",
  },

  [THEME.breakpoints.down("xs")]: {
    fontSize: "10px",
    padding: "3px 6px",
  },
}));

export const ConditionsContainer = styled(
  "div",
  {}
)(() => ({
  marginLeft: "40px",

  [THEME.breakpoints.down("xl")]: {
    marginLeft: "15px",
  },

  [THEME.breakpoints.down("md")]: {
    marginLeft: "0",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const Condition = styled(
  "div",
  {}
)(() => ({
  marginBottom: "20px",

  [THEME.breakpoints.down("md")]: {
    marginBottom: "15px",
  },
}));

export const ConditionTitle = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  color: `rgba(${COLOR.TEXT_RGB}, .4)`,
  fontSize: "20px",
  lineHeight: 1,

  [THEME.breakpoints.down("sm")]: {
    fontSize: "14px",
  },

  [THEME.breakpoints.down("xs")]: {
    fontSize: "12px",
  },
}));

export const ConditionValue = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "28px",
  lineHeight: 1,
  color: COLOR.TEXT,

  [THEME.breakpoints.down("sm")]: {
    fontSize: "20px",
  },

  [THEME.breakpoints.down("xs")]: {
    fontSize: "16px",
  },
}));

export const CardButton = styled(
  "a",
  {}
)(() => ({
  borderRadius: "30px",
  backgroundColor: COLOR.TEXT,
  height: "44px",
  padding: "10px",
  appearance: "none",
  color: COLOR.WHITE,
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "18px",
  lineHeight: 1.2,
  textDecoration: "none",
  textAlign: "center",
  flex: "0 0 100%",

  [THEME.breakpoints.down("xs")]: {
    padding: "5px",
    height: "27px",
    fontSize: "14px",
  },
}));
