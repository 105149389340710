import { createTheme } from "@mui/material";

import HelveticaNeueCyr from "../assets/fonts/HelveticaNeueCyr.woff";

export const FONT_FAMILY = {
  HELVETICA: "Helvetica",
};

export const COLOR = {
  WHITE: "#FFF",
  LIGHT_GRAY: "#F0F3F9",
  TEXT: "#08081C",
  TEXT_RGB: "8, 8, 28",
  BEST_CARD: "#FFEBB8",
};

export const THEME = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Helvetica';
          font-style: normal;
          font-weight: normal;
          src: url(${HelveticaNeueCyr}) format('woff')
        }
      `,
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
        valueLabel: {
          backgroundColor: COLOR.TEXT,
          borderRadius: "4px",
          padding: "3px 6px 1px 6px",
          fontFamily: FONT_FAMILY.HELVETICA,
          color: COLOR.WHITE,
          fontSize: "18px",
          lineHeight: "1.3",

          "@media (max-width: 768px)": {
            fontSize: "12px",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          date: "span",
          tag: "span",
          loader: "span",
          search: "span",
          modal: "span",
          h1dark: "h1",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 768,
      md: 991,
      lg: 1350,
      xl: 1750,
    },
  },
});
