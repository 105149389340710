import { Container } from "@mui/material";

import { styled } from "@mui/system";

import { COLOR } from "../../themes/main";
import { THEME } from "../../themes/main";

export const CustomContainer = styled(
  Container,
  {}
)(() => ({
  backgroundColor: COLOR.WHITE,
  padding: "30px 20px 20px",
  maxWidth: "100% !important",
  marginBottom: "10px",

  [THEME.breakpoints.down("md")]: {
    padding: 0,
    margin: 0,
  },
}));

export const Body = styled("div")({
  [THEME.breakpoints.down("md")]: {
    padding: "0",
    width: "100%",
  },
});
