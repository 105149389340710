import { styled } from "@mui/system";
import { COLOR, FONT_FAMILY } from "../../themes/main";
import { THEME } from "../../themes/main";

export const Container = styled(
  "div",
  {}
)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "10px",

  [THEME.breakpoints.down("md")]: {
    flexDirection: "column",
    marginTop: 0,
  },
}));

export const Conditions = styled(
  "div",
  {}
)(() => ({
  borderRadius: "20px",
  backgroundColor: COLOR.LIGHT_GRAY,
  padding: "120px 50px 0",
  flex: "0 0 calc(50% - 5px)",

  [THEME.breakpoints.down("xl")]: {
    flex: "0 0 calc(40% - 5px)",
    padding: "40px 20px 0px",
  },

  [THEME.breakpoints.down("md")]: {
    borderRadius: "8px",
    marginBottom: "5px",
    padding: "20px 10px",
  },

  [THEME.breakpoints.down("xs")]: {
    paddingBottom: 0,
  },
}));

export const ConditionsTitle = styled(
  "h1",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  color: COLOR.TEXT,
  fontSize: "96px",
  marginTop: 0,
  marginBottom: "30px",
  lineHeight: 0.9,

  [THEME.breakpoints.down("xl")]: {
    fontSize: "70px",
  },

  [THEME.breakpoints.down("lg")]: {
    fontSize: "50px",
  },

  [THEME.breakpoints.down("md")]: {
    fontSize: "38px",
    maxWidth: "350px",
    marginBottom: "10px",
  },

  [THEME.breakpoints.down("xs")]: {
    fontSize: "24px",
    maxWidth: "300px",
  },
}));

export const ConditionsText = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  color: `rgba(${COLOR.TEXT_RGB}, .7)`,
  lineHeight: 1.2,
  fontSize: "36px",
  marginBottom: "55px",

  [THEME.breakpoints.down("xl")]: {
    fontSize: "30px",
  },

  [THEME.breakpoints.down("lg")]: {
    fontSize: "26px",
  },

  [THEME.breakpoints.down("md")]: {
    maxWidth: "500px",
    marginBottom: "30px",
  },

  [THEME.breakpoints.down("xs")]: {
    fontSize: "14px",
  },
}));

export const Cards = styled(
  "div",
  {}
)(() => ({
  flex: "0 0 calc(50% - 5px)",

  [THEME.breakpoints.down("xl")]: {
    flex: "0 0 calc(60% - 5px)",
  },
}));

export const SliderWrap = styled(
  "div",
  {}
)(() => ({
  marginBottom: "45px",
  display: "flex",

  [THEME.breakpoints.down("lg")]: {
    flexDirection: "column",
  },

  [THEME.breakpoints.down("md")]: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
  },

  [THEME.breakpoints.down("sm")]: {
    paddingRight: "40px",
  },
}));

export const SliderText = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "18px",
  lineHeight: 1.2,
  color: `rgba(${COLOR.TEXT_RGB}, .7)`,
  minWidth: "155px",
  marginRight: "70px",

  [THEME.breakpoints.down("lg")]: {
    marginRight: 0,
  },

  [THEME.breakpoints.down("md")]: {
    minWidth: "unset",
    flex: "0 0 100px",
    marginRight: "20px",
  },

  [THEME.breakpoints.down("xs")]: {
    fontSize: "12px",
    flex: "0 0 70px",
  },
}));

export const BestText = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "30px",
  lineHeight: 1.2,
  color: `rgba(${COLOR.TEXT_RGB}, .7)`,
  textAlign: "center",
  marginBottom: "30px",

  [THEME.breakpoints.down("lg")]: {
    fontSize: "26px",
  },

  [THEME.breakpoints.down("md")]: {
    maxWidth: "500px",
    marginBottom: "30px",
  },

  [THEME.breakpoints.down("xs")]: {
    fontSize: "14px",
  },
}));
