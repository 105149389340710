import React, { useState } from 'react';

import { FooterContainer, FooterText, UnsubscribeLink } from './styles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import MaskedInput from '../masked-input';
import emailjs from '@emailjs/browser';

import { TEMPLATE_ID, SERVICE_ID, EMAIL_JS } from '../../const/email';

const Footer = () => {
  const [phone, setPhone] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const formSubmit = () => {
    if (phone.includes('_')) {
      setIsValid(false);
      
      return;
    }

    setIsValid(true);
    setIsOpen(false);

    emailjs.send(SERVICE_ID, TEMPLATE_ID, { phone: phone }, EMAIL_JS)
      .then(response => {
      console.log('success', response.status, response.text);
        setIsSuccessOpen(true);
    }, err => console.log('error', err));
  };

  const closeSuccessPopup = () => {
    setIsSuccessOpen(false);
  }

  return (
    <FooterContainer>
      <FooterText>
        ИП Савельев Андрей Владимирович. ИНН: 860300470938.<br/>
        Сайт НЕ является представительством МФО или банком, не выдает займов и кредитов. Все рекомендуемые на сайте кредитные учреждения имеют соответствующие лицензии. Условия неуплаты можно уточнить на сайте МФО. 
      </FooterText>
      <UnsubscribeLink onClick={openPopup}>Отписаться</UnsubscribeLink>
      <Dialog open={isOpen} onClose={closePopup}>
        <DialogTitle>Отписаться</DialogTitle>
        <DialogContent>
          <DialogContentText>Введите номер телефона, чтобы отписаться</DialogContentText>
          <MaskedInput 
            setPhone={setPhone}
            isValid={isValid}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closePopup}>Отмена</Button>
          <Button onClick={formSubmit}>Отписаться</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isSuccessOpen} onClose={closeSuccessPopup}>
        <DialogContent>
          <DialogContentText>Спасибо, мы исключили вас из рассылки</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSuccessPopup}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </FooterContainer>
  );
};

export default React.memo(Footer);