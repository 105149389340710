import React from "react";
import { useMask } from "@react-input/mask";
import { TextField } from "@mui/material";

const ERROR_TEXT = "Некорректное значение";

const MaskedInput = ({ setPhone, isValid }) => {
  const inputRef = useMask({
    mask: "+7 (___) ___-__-__",
    showMask: "true",
    replacement: { _: /\d/ },
  });

  return (
    <TextField
      autoFocus
      error={!isValid}
      type="text"
      fullWidth
      inputRef={inputRef}
      onChange={(e) => setPhone(e.target.value)}
      helperText={isValid ? "" : ERROR_TEXT}
    />
  );
};

export default React.memo(MaskedInput);
