import { styled } from "@mui/system";

import { COLOR, FONT_FAMILY, THEME } from "../../themes/main";

export const FooterContainer = styled(
  "footer",
  {}
)(() => ({
  padding: "30px 20px",

  [THEME.breakpoints.down("sm")]: {
    padding: "20px 10px",
  },
}));

export const FooterText = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "14px",
  lineHeight: 1.25,
  color: `rgba(${COLOR.TEXT_RGB}, .4)`,

  [THEME.breakpoints.down("xs")]: {
    fontSize: "10px",
  },
}));

export const UnsubscribeLink = styled(
  "a",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "14px",
  lineHeight: 1.25,
  color: `rgba(${COLOR.TEXT_RGB}, .4)`,
  appearance: "none",
  marginTop: "15px",
  display: "inline-block",
  cursor: "pointer",
}));
