import { styled } from "@mui/system";

import { COLOR, FONT_FAMILY, THEME } from "../../themes/main";

export const CustomHeader = styled(
  "header",
  {}
)(({ isOpen }) => ({
  padding: "0 20px",
  backgroundColor: COLOR.LIGHT_GRAY,
  height: "90px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [THEME.breakpoints.down("md")]: {
    padding: 0,
    width: "25px",
    height: "16px",
    position: isOpen ? "fixed" : "absolute",
    top: "20px",
    right: "30px",
    zIndex: 100,
  },
}));

export const Title = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  fontSize: "28px",
  color: COLOR.TEXT,

  [THEME.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const Link = styled(
  "a",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  color: `rgba(${COLOR.TEXT_RGB}, 0.2)`,
  fontSize: "18px",

  [THEME.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const Burger = styled(
  "div",
  {}
)(() => ({
  display: "none",
  [THEME.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    zIndex: 101,
    display: "block",
  },
}));

export const BurgerSpan = styled(
  "span",
  {}
)(({ isOpen, isHidden }) => ({
  display: "block",
  height: "2px",
  width: "100%",
  backgroundColor: COLOR.TEXT,
  marginBottom: "4px",
  transition: "all .4s",
  transform: isOpen ? "rotate(-45deg) translate(3px, -3px)" : "",

  "&:first-of-type": {
    transform: isOpen ? "translate(0px, 2px) rotate(45deg)" : "",
  },

  "&:last-child": {
    marginBottom: 0,
    opacity: isHidden && isOpen ? 0 : 1,
  },
}));

export const MobileMenu = styled(
  "div",
  {}
)(({ isOpen }) => ({
  display: isOpen ? "block" : "none",
  position: "fixed",
  top: 0,
  left: 0,
  height: "150px",
  width: "100vw",
  backgroundColor: COLOR.LIGHT_GRAY,
  boxShadow: "0px 2px 4px 0px rgba(8, 8, 28, 0.05)",
  zIndex: 100,
  padding: "10px",
}));

export const MobileTitle = styled(
  "div",
  {}
)(() => ({
  fontFamily: FONT_FAMILY.HELVETICA,
  color: COLOR.TEXT,
  fontSize: "20px",
  maxWidth: "140px",
  lineHeight: 0.95,
  marginBottom: "30px",
}));

export const MobileLink = styled(
  "a",
  {}
)(() => ({
  appearance: "none",
  maxWidth: "130px",
  fontSize: "10px",
  textDecoration: "underline",
  color: `rgba(${COLOR.TEXT_RGB}, .2)`,
  display: "block",
  lineHeight: 1,
  marginBottom: "10px",
}));

export const LinkContainer = styled(
  "div",
  {}
)(() => ({
  display: "flex",
  flexDirection: "column",
}));
