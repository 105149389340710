import React from 'react';

import { Body, CustomContainer } from './styles';

import Header from '../header';
import Footer from '../footer';
import ChooseCard from '../../views/choose-card';

const Layout = () => {
  return (
    <CustomContainer>
      <Header />
      <Body>
        <ChooseCard />
      </Body>
      <Footer />
    </CustomContainer>
  );
};

export default React.memo(Layout);